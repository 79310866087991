<template>
  <div class="cookie-modal" v-if="!showCookieModal">
    <div class="cookie-content">
      <span v-if="manageIsVisible">
        <div class="chekbox-container">
          <label class="chekbox-label">
            <div class="chekbox-label-wrapper">
              <input type="checkbox" disabled v-model="essential" />
              <div class="checkbox"></div>
              <div class="title small">Advertising</div>
              <div class="desc">
                Advertising cookies are used to make advertising messages more
                relevant to you.
              </div>
            </div>
          </label>
        </div>
        <div class="chekbox-container">
          <label class="chekbox-label">
            <div class="chekbox-label-wrapper">
              <input type="checkbox" v-model="optional" />
              <div class="checkbox"></div>
              <div class="title small">Customization Cookies</div>
              <div class="desc">
                We use these cookies to gather information on how our website is
                being used so we can continuously improve it.
              </div>
            </div>
          </label>
        </div>
        <div class="chekbox-container">
          <label class="chekbox-label">
            <div class="chekbox-label-wrapper">
              <input type="checkbox" v-model="google" />
              <div class="checkbox"></div>
              <div class="title small">Functionality Cookies</div>
              <div class="desc">
                These cookies improve the performance and functionality of our
                website but are not essential for its use.
              </div>
            </div>
          </label>
        </div>

        <button class="button" @click="acceptCookies">Accept all</button>
        <button @click="acceptCookies" class="button">Accept selected</button>
        <button @click="declineCookies" class="button gray manage-button">
          Decline
        </button>
      </span>
      <span v-else>
        <div class="title small">Cookies In Use</div>

        <p class="desc" v-if="textPageList && textPageList.length">
          We employ cookies to ensure our website works seamlessly and to
          improve your user experience. Essential cookies are always active due
          to their necessity for basic site operations. You have control over
          additional cookies. For more details, please read our
          <a
            @click="
              $parent.goToPage(
                textPageList.find((item) => item.title.includes('Cookie')).id
              )
            "
            class="desc link"
            >{{
              textPageList.find((item) => item.title.includes("Cookie")).title
            }}</a
          >.
        </p>
        <button class="button" @click="acceptCookies">Ok, got it!</button>
        <button @click="declineCookies" class="button gray decline-button">
          Decline
        </button>
        <button @click="manageCookies" class="button gray manage-button">
          Manage cookies
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-js-cookie";

export default {
  props: ["textPageList"],
  data() {
    return {
      showCookieModal: VueCookies.get("cookiesAccepted"), // Check if user accepted cookies
      manageIsVisible: false,
      essential: true,
      google: false,
      optional: false,
    };
  },
  methods: {
    acceptCookies() {
      this.showCookieModal = true;
      VueCookies.set("cookiesAccepted", "true", 365); // Set a cookie that lasts for a year
    },
    declineCookies() {
      this.showCookieModal = true;
      VueCookies.set("cookiesAccepted", "false", 365);
    },
    manageCookies() {
      this.manageIsVisible = true;
    },
  },
};
</script>
<style scoped>
.chekbox-label-wrapper input {
    width: 34px;
    height: 34px;
    padding: 0;
}
.cookie-modal {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 100%;
  max-width: 450px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  z-index: 3;
}
.cookie-modal .desc {
  color: #000;
  font-size: 14px;
  line-height: 18px;
}
.cookie-modal .title {
  color: #000;
}
.button {
  margin: 12px 4px 0;
  width: 100px;
  display: inline-block;
  color: #000;
  padding: 8px 10px;
  font-size: 14px;
}
.cookie-content {
  font-size: 14px;
  margin-bottom: 10px;
}
.cookie-content .button {
  width: auto;
}
.cookie-content .chekbox-label .title.small {
  color: #000;
  font-size: 18px;
  line-height: 24px;
}
.cookie-content .chekbox-container + .chekbox-container {
  margin-top: 16px;
}
.accept-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.accept-button:hover {
  background-color: #0056b3;
}
</style>
