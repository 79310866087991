<template>
  <main class="main">
    <div class="first-section-container">
      <img src="../assets/bg.webp" class="bg"/>
      <div class="section first-section">
        <div class="wrapper">
          <div class="title text-white">PAYMENT DETAILS</div>
          <div class="form">
            <label>
              <span class="title-tiny">FIRST NAME:</span>
              <input type="text" v-model="name" placeholder="Your first name"/>
            </label>
            <label>
              <span class="title-tiny">LAST NAME:</span>
              <input type="text" v-model="surname" placeholder="Your last name"/>
            </label>
            <label>
              <span class="title-tiny">PHONE:</span>
              <input type="number" v-model="phone" placeholder="Your phone number"/>
            </label>
            <label>
              <span class="title-tiny">EMAIL:</span>
              <input type="email" v-model="email" placeholder="Your email"/>
            </label>
            <label>
              <span class="title-tiny">ADDRESS:</span>
              <input type="text" v-model="address" placeholder="Your address"/>
            </label>
            <label>
              <span class="title-tiny">CITY:</span>
              <input type="text" v-model="city" placeholder="Your city"/>
            </label>
            <label>
              <span class="title-tiny">COUNTRY:</span>
              <select v-model="country">
                <option value="" disabled selected>Your county</option>
                <option v-for="item in countryOptions" :value="item.id" :key="item.title">{{item.title}}</option>
              </select>
            </label>
            <label>
              <span class="title-tiny">ZIP CODE:</span>
              <input type="text" v-model="postCode" placeholder="Your zip code"/>
            </label>
            <div :class="['button', {'disabled': !requiredFieldsAreFilled}]" @click="submit">Pay now</div>
            <transition name="fade">
              <div class="desc error" v-if="$parent.error">{{$parent.error}}</div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Cart',
  props: [],
  components: {
  },
  data: function() {
    return {
      imgDomain: '',
      countryOptions: [],
      name: '',
      surname: '',
      phone: '',
      email: '',
      address: '',
      city: '',
      country: '',
      postCode: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getProfile();
    this.getCounties();
    if (this.$route.query.amount) {
      this.$emit('setAmount', this.$route.query.amount);
    }
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.email && this.country && 
        this.city && this.address  
        && this.postCode 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    submit() {
      let amount = this.$parent.amount;
      let data = {
        "first_name": this.name,
        "last_name": this.surname,
        "phone": this.phone,
        "email": this.email,
        "country_id": this.country,
        "city": this.city,
        "address": this.address,
        "zip": this.postCode,
        "amount": amount
      }
      this.$emit('topUp', data)
    },
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
         
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    }
  }
}
</script>