<template>
  <main class="main">
    <div class="first-section-container profile">
      <img src="../assets/bg.webp" class="bg"/>
      <div class="section first-section">
        <div class="wrapper">
          <div class="profile-content">
            <div class="tab-list">
              <router-link to="/profile" class="subtitle">PROFILE</router-link>
              <router-link to="/history" class="subtitle active">HISTORY</router-link>
            </div>
            <div class="history-info">
              <div class="title-tiny-osw">Items:</div>
              <div class="table-container" v-if="$parent.orderHistory && $parent.orderHistory.length">
                <table>
                  <thead>
                    <tr>
                      <td>DATE</td>
                      <td>NAME</td>
                      <td>STATUS</td>
                      <td>COUNT</td>
                      <td>TOTAL PRICE</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in $parent.orderHistory" :key="i">
                      <td>{{$parent.formatDate(item.date)}}</td>
                      <td>{{item.name}}</td>
                      <td>{{item.status}}</td>
                      <td>x{{item.count}}</td>
                      <td>{{item.total_price}} {{item.currency}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="title-tiny-osw">Top Up:</div>
              <div class="table-container"  v-if="$parent.depositHistory && $parent.depositHistory.length">
                <table>
                  <thead>
                    <tr>
                      <td>DATE</td>
                      <td>STATUS</td>
                      <td>AMOUNT</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in $parent.depositHistory" :key="i">
                      <td>{{$parent.formatDate(item.date)}}</td>
                      <td>{{item.status}}</td>
                      <td>{{item.amount}}  {{item.currency}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div class="pagination">
                <div class="prev disabled"></div>
                <div class="title-tiny">1</div>
                <div class="title-tiny">2</div>
                <div class="title-tiny">...</div>
                <div class="title-tiny">5</div>
                <div class="next"></div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'History',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  mounted() {
    
  },
  methods: {
    
  }
}
</script>